import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { ContentLoader } from '@saucelabs/sl-component-loader';
import NotificationsContainer from '@saucelabs/sl-container-notifications';

import * as operations from './../operations';
import containerSelector from './../../selectors';

class Application extends Component {
  componentDidMount() {
    const { initialize } = this.props;
    initialize();
  }

  render() {
    const { app, children } = this.props;
    return (
      <ContentLoader
        show={!app.get('initialized')}
      >
        <div className="flex min-h-screen bg-brand-mainpage pb-9">
          <Helmet
            defaultTitle="Sauce Labs Login"
            titleTemplate="Sauce Labs Login | %s"
          />
          <NotificationsContainer />
          {children}
        </div>
      </ContentLoader>
    );
  }
}

Application.propTypes = {
  app: ImmutablePropTypes.map.isRequired,
  children: PropTypes.node.isRequired,
  initialize: PropTypes.func.isRequired,
};

const mapStateToProps = containerSelector();
const mapDispatchToProps = {
  initialize: operations.initialize,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Application);
export { Application, mapStateToProps, mapDispatchToProps };
