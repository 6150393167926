import { Map } from 'immutable';

import * as types from './types';

const initialState = new Map({
  authId: '',
  hasSession: false,
  successUrl: '',
});

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_AUTH_ID.SUCCESS: {
      return state.set('authId', payload.authId);
    }

    case types.GET_SESSION.SUCCESS: {
      return state.set('hasSession', true);
    }
    case types.GET_SESSION.FAILURE: {
      return state.set('hasSession', false);
    }

    case types.AUTHENTICATE.SUCCESS: {
      return state.set('successUrl', payload?.successUrl || '');
    }
    case types.AUTHENTICATE.FAILURE: {
      return state.set('successUrl', '');
    }

    default:
      return state;
  }
};

export default authReducer;
export { initialState };
