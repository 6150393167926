import '@babel/polyfill';
import { createRoot } from 'react-dom/client';
import '@saucelabs/sl-style-main';
import './tailwind.css';
import '@saucelabs/sl-util-sentry';
import config from '@saucelabs/sl-util-config';

import router from './router';

config.load(window.WEB_SETTINGS);
const container = document.getElementById('app');
const root = createRoot(container);
root.render(router);

// NOTE: should be last to don't block more important requests
import '@saucelabs/sl-style-font/lib/fonts.css'; // eslint-disable-line
