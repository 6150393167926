import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import LoginRoutes from '@saucelabs/accounts-login/src/routes';

import ApplicationContainer from './application/containers/ApplicationContainer';
import AdminRoute from './components/AdminRoute';
import store, { browserHistory } from './store';

const router = (
  <Provider store={store}>
    <ConnectedRouter history={browserHistory}>
      <ApplicationContainer>
        <Switch>
          <AdminRoute
            component={LoginRoutes}
            path="/"
          />
          <Redirect
            push
            to="/404"
          />
        </Switch>
      </ApplicationContainer>
    </ConnectedRouter>
  </Provider>
);

export default router;
