import * as actions from './actions';

import { actions as loginActions, operations as loginOperations } from '@saucelabs/accounts-login/src/ducks';

export const initialize = () => async (dispatch) => {
  try {
    // TODO:
    // After reimplementing whole UI from scratch and getting better context,
    // the flow for initialization of UI is as follows:
    // We should always call getSession, as this also redirect users if session
    // is valid, so we don't need to display UI forms, etc.
    //
    // After that depending on view, we either should all auth, for login views,
    // this way we get authId, or we submit props that return from social logins.
    //
    // Or in case of forgotten password, we should call forgottenPassword endpoint.
    // That means, forgotten password, doesn't require auth call at all,
    // while login page doesn't call forgotten password.
    await dispatch(loginActions.getAuthId());
    // TODO: Merge getAuthenticateInfo with getAuthId, or find different way for calling them,
    // as we don't need two calls at once.
    await dispatch(loginOperations.getAuthenticateInfo());
    // Facade calls
    await dispatch(loginOperations.newGetSession());
  } catch ({ response }) {
    console.log(response);
  } finally {
    dispatch(actions.initialized());
  }
};
