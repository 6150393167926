import { Map } from 'immutable';

import * as types from './types';

const initialState = new Map({
  initialized: false,
});

const appReducer = (state = initialState, { type }) => {
  switch (type) {
    case types.INITIALIZED:
      return state.set('initialized', true);
    default:
      return state;
  }
};

export default appReducer;
export { initialState };
