import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import { BundleRoute } from '@saucelabs/sl-component-router';

import SessionCheckContainer from './containers/SessionCheckContainer';

const importLogin = () => import(/* webpackChunkName: "a-login" */ './containers/LoginContainer');
const importLegacyLogin = () => import(/* webpackChunkName: "b-login" */ './containers/LoginContainerLegacy');
const importNewPassword = () => import(/* webpackChunkName: "a-new-password" */ './containers/NewPasswordContainer');

const LoginRoutes = ({ match }) => (
  <SessionCheckContainer>
    <Switch>
      <BundleRoute
        load={importNewPassword}
        path={`${match.url}new-password`}
      />

      <BundleRoute
        load={importLegacyLogin}
        path={`${match.url}legacy`}
      />
      <BundleRoute
        load={importLogin}
        path={match.url}
      />
    </Switch>
  </SessionCheckContainer>
);

LoginRoutes.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
};

export default LoginRoutes;
