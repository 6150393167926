import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const SessionCheckContainer = ({
  children,
  hasSession,
}) => {
  console.log('Has session???', hasSession);
  if (hasSession) {
    return null;
  }

  return (
    <div className="flex flex-col justify-center max-w-64 min-h-full">
      {children}
    </div>
  );
};

SessionCheckContainer.propTypes = {
  children: PropTypes.node.isRequired,
  hasSession: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ apps }) => ({
  hasSession: apps.auth.get('hasSession', false),
});

export default connect(mapStateToProps)(SessionCheckContainer);
export { SessionCheckContainer };
