import { cookiesHelper } from '@saucelabs/sl-util-helpers';

export const redirectTo = (url) => {
  window.location = url;
};

export const formatPasswordResetFirstStepPayload = ({ token = '', code = '' }) => ({
  token,
  input: {
    code,
  },
});

export const formatPasswordResetSecondStepPayload = (payload, password) => {
  console.log({
    payload,
    password,
  });
  const { token, requirements } = payload;
  const { code } = requirements;

  return {
    token,
    code,
    input: {
      password,
    },
  };
};

// Custom cookie, to avoid usage of reentry cookie. This way we will get AuthID for
// e.g. Google flow when returning to log in UI.
const cookieName = 'auth_id_cookie';
const cookieDomain = '.saucelabs.net';

export const getAuthIdCookie = () => cookiesHelper.getItem(cookieName);

// TODO: Support setting cookie domain based on current URL.
// This should have an allow list that we check against.
export const setAuthIdCookie = (value) => {
  const expireTime = 3 * 60; // 3 minutes
  const expireDate = (new Date(Date.now() + expireTime * 1000)).toUTCString();
  console.log({ expireDate });
  return cookiesHelper.setItem(cookieName, value, expireDate, '/', cookieDomain, true);
};

export const clearAuthIdCookie = () => cookiesHelper.removeItem(cookieName, '/', cookieDomain);
