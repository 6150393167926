import { actions as notificationActions } from '@saucelabs/sl-container-notifications';

import * as actions from './actions';
import * as helpers from './helpers';

/**
 * Operation that tries to send all current params, including state, code etc. To AM to check if
 * we can continue with e.g. Google log in. In this case we need to send AuthID that started
 * the process, thus we need to extract it from our custom cookie.
 */
export const getAuthenticateInfo = () => (dispatch, getState) => {
  const { router } = getState();
  const locationSearch = router?.location?.search;
  const queryParams = router?.location?.query;

  const authId = helpers.getAuthIdCookie();
  helpers.clearAuthIdCookie();

  const data = {
    payload: { authId, ...queryParams },
    queryParams: locationSearch,
  };

  console.log(data);

  return dispatch(actions.authenticate(data))
    .then(() => {
      console.log('Authenticate done!');
      // dispatch(getSession());
    })
    .catch(({ response }) => {
      dispatch(notificationActions.notifyError(response?.data?.message || 'There was a problem with logging in.'));
    });
};

/**
 * Dedicated auth operation, that starts process of Google Login.
 * We need to separate it into smaller function, as most of the logic is the same,
 * we only arrange data in different way and extract authId and redirect URL from response.
 * This should be reusable for GitHub flow. As well for SSO login.
 */
export const authenticateWithGoogle = () => (dispatch) => {
  // const { router } = getState();

  // TODO: Support passing allowed params, like goto, next, region etc.
  // const queryParams = router?.location?.query;
  const flow = 'googleTree';
  const googleFlowParams = {
    // ...queryParams,
    service: flow,
    authIndexType: 'service',
    authIndexValue: flow,
  };

  console.log(googleFlowParams);

  const encodeGetParams = (params) => Object.entries(params).map((kv) => kv.map(encodeURIComponent).join('=')).join('&');

  const queryString = encodeGetParams ? `?${encodeGetParams(googleFlowParams)}` : '';
  console.log(queryString);

  const data = {
    payload: {},
    queryParams: queryString,
  };

  return dispatch(actions.authenticate(data))
    .then((response) => {
      // TODO: Improve decoding response from social logins
      const { data: { authId, callbacks } } = response;
      const { output } = callbacks[0];
      const redirectUri = output[0].value;

      console.log(authId);
      helpers.setAuthIdCookie(authId);

      const extractedId = helpers.getAuthIdCookie();
      console.log({ extractedId });

      console.log(redirectUri);
      window.location = redirectUri;
    })
    .catch(({ response }) => {
      dispatch(notificationActions.notifyError(response?.data?.message || 'There was a problem with logging in.'));
    });
};

export const authenticateWithSso = () => (dispatch) => {
  // const { router } = getState();

  // TODO: Support passing allowed params, like goto, next, region etc.
  const flow = 'saml';
  // TODO: Get this from SSO service. Right now hardcoded for `marcingodlewski-sl-staging` account.
  const flowParams = {
    service: flow,
    sp_meta_alias: '%2Fauthtree%2Fsp',
    idp_entity_id: 'http%3A%2F%2Fwww.okta.com%2Fexk77r03zbywyFuzp697',
    authIndexType: 'service',
    authIndexValue: 'saml',
  };

  console.log(flowParams);

  const queryString = '?service=saml&sp_meta_alias=%2Fauthtree%2Fsp&idp_entity_id=http%3A%2F%2Fwww.okta.com%2Fexk77r03zbywyFuzp697&region=us-west-2&authIndexType=service&authIndexValue=saml';
  console.log(queryString);

  const data = {
    payload: {},
    queryParams: queryString,
  };

  return dispatch(actions.authenticate(data))
    .then((response) => {
      console.log(response);
      // // TODO: Improve decoding response from social logins
      const { data: { authId, callbacks } } = response;
      const { output } = callbacks[0];
      const redirectUri = output[0].value;

      console.log(authId);
      helpers.setAuthIdCookie(authId);

      const extractedId = helpers.getAuthIdCookie();
      console.log({ extractedId });

      // We will return to accounts.saucelabs.com after successfully IDP login.
      // So this flow won't work out of the box. But we really just need to copy
      // returned params from accounts page to local ui, and authentication will succeed.
      console.log(redirectUri);
      window.location = redirectUri;
    })
    .catch(({ response }) => {
      dispatch(notificationActions.notifyError(response?.data?.message || 'There was a problem with logging in.'));
    });
};

/**
 * This operation consists of two steps merged into one. In XUI, once user visits reset page
 * with code and token, there is a request with code a token to forgottenPassword endpoint.
 * This exhaust token, so refreshing page, results in Invalid code response.
 * By the look of it, this was done as a security feature, but we hand
 * previously ticket regarding this, as this creates kind of bad UX.
 * As we force user to restart process to get a new token.
 * After that, once users fills new password, we send new token and code,
 * that we get from first request to backend.
 *
 * In our approach, which is POC, I have bulked both requests into one.
 * So current flow looks like this: user visits page, fills new password form,
 * we submit the form with all data.
 * This operation handles both requests, so we first make a call with code and token
 * , and then second one.
 * Second request has both new token and code from first request as well new password.
 * If that succeeds, we have successfully changed users password.
 *
 * TODO:
 * This operations needs serious refactoring, and split. As we can call a next step
 * by calling separate operation.
 * We should also consider if we want to proceed with bulked requests,
 * or make it as XUI does it, so two different requests.
 */
export const resetPassword = (payload) => async (dispatch, getState) => {
  const { router } = getState();
  const queryParams = router?.location?.query;

  const formattedFirstStepPayload = helpers.formatPasswordResetFirstStepPayload({
    token: queryParams.token,
    code: queryParams.code,
  });

  console.log({
    payload,
    queryParams,
    formattedFirstStepPayload,
  });

  let firstResponse = null;
  try {
    firstResponse = await dispatch(actions.resetPassword(formattedFirstStepPayload));
  } catch (error) {
    const { response } = error;
    console.log('Catch first request response', { response });
    dispatch(notificationActions.notifyError(response?.data?.message || 'Failed to reset password'));
    return;
  }

  console.log('Success first response', { firstResponse });

  const formattedSecondStepPayload = helpers.formatPasswordResetSecondStepPayload(
    firstResponse.data,
    payload.password,
  );

  let secondResponse = null;
  try {
    secondResponse = await dispatch(actions.resetPassword(formattedSecondStepPayload));
  } catch (error) {
    const { response } = error;
    console.log('Catch second request response', { response });
    dispatch(notificationActions.notifyError(response?.data?.message || 'Failed to reset password'));
    return;
  }

  console.log('Success second response', { secondResponse });
  dispatch(notificationActions.notifySuccess('Password changed !'));
};

// New operations using auth-service as facade
export const newAuthenticateWithPassword = (payload) => (dispatch, getState) => {
  const { router } = getState();

  const locationSearch = router?.location?.search;
  const data = {
    payload: {
      username: payload.username,
      password: payload.password,
    },
    queryParams: locationSearch,
  };

  return dispatch(actions.newAuthenticateWithPassword(data))
    .then((response) => {
      const redirectUrl = response?.data.redirect_url;
      helpers.redirectTo(redirectUrl);
    })
    .catch(({ response }) => {
      dispatch(notificationActions.notifyError(response?.data?.message || 'There was a problem with logging in.'));
    });
};

export const newGetSession = () => (dispatch) => (
  dispatch(actions.newGetSession())
    .then((response) => {
      const redirectUrl = response?.data.redirect_url;
      helpers.redirectTo(redirectUrl);
    })
    .catch(() => {
      console.log('New get session failed!');
    })
);

export const initiatePasswordReset = (payload) => (dispatch) => (
  dispatch(actions.initiatePasswordReset(payload))
    .then(() => {
      dispatch(notificationActions.notifySuccess('Email was sent'));
    })
    .catch(({ response }) => {
      dispatch(notificationActions.notifyError(response?.data?.message || 'There was a problem with password reset.'));
    })
);
