import { createApiAction } from '@saucelabs/sl-util-redux';

import * as api from './api';
import * as types from './types';

export const getAuthId = createApiAction(types.GET_AUTH_ID, api.getAuthId);
export const authenticate = createApiAction(types.AUTHENTICATE, api.authenticate);
export const resetPassword = createApiAction(
  types.PASSWORD_RESET,
  api.resetPassword,
);

// New actions hidden behind facade
export const newAuthenticateWithPassword = createApiAction(
  types.AUTHENTICATE,
  api.newAuthenticateWithPassword,
);

export const newGetSession = createApiAction(
  types.GET_SESSION,
  api.newGetSession,
);

export const initiatePasswordReset = createApiAction(
  types.PASSWORD_RESET,
  api.initiatePasswordReset,
);
