import { createAxios } from '@saucelabs/sl-util-axios';
import { apiBaseUrls } from '@saucelabs/sl-util-config';

const forgerockAm = createAxios(apiBaseUrls.forgerockAm, {
  headers: {
    common: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  },
});

const authService = createAxios(apiBaseUrls.authService, {
  headers: {
    common: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  },
});

/**
 * Requests authentication id. This needs to be sent in authentication requests.
 * We store it in redux, and sent with authenticate request.
 */
export const getAuthId = () => (
  forgerockAm.post('/json/realms/root/realms/authtree/authenticate')
);

// TODO: Create separate api action for social login flows as we
// don't want to use the same TYPES. This will avoid issues with storage in future.
/**
 * Authenticates user by sending username, password, and authId to AM.
 * This call also return sl-auth cookie and saves it in the browser.
 * On success returns: tokenId, successUrl, and realm
 */
export const authenticate = ({ payload = {}, queryParams = '' }) => (
  forgerockAm.post(`/json/realms/root/realms/authtree/authenticate${queryParams}`, payload)
);

/**
 * Request that allows starting reset password flow. It is also used in second and final steps
 * of password reset flow.
 * Depending on payload or response, we can know were we are in reset password flow.
 */
export const resetPassword = (payload) => (
  forgerockAm.post('/json/realms/root/realms/authtree/selfservice/forgottenPassword?_action=submitRequirements', payload)
);

// New endpoints hidden behind facade
export const newAuthenticateWithPassword = ({ payload = {}, queryParams = '' }) => (
  authService.post(`/v1/accounts/authenticate/password/${queryParams}`, payload)
);

/**
 * Checks for active user session. Require usage of authenticate so sl-auth cookie is present.
 * If no active session is found, return 401.
 * If session is active, returns details of it, including additional properties like:
 * redirect_url, max_idle_expiration_time.
 */
export const newGetSession = () => (
  authService.get('/v1/accounts/session/')
);

export const initiatePasswordReset = (payload) => (
  authService.post('/v1/accounts/password/reset/', payload)
);
