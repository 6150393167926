import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducers as modalReducer } from '@saucelabs/sl-container-modal';
import { reducers as notificationsReducer } from '@saucelabs/sl-container-notifications';

import applicationReducer from './application/reducers';

import authReducer from '@saucelabs/accounts-login/src/ducks/reducers';

const apps = combineReducers({
  app: applicationReducer,
  auth: authReducer,
});

const createReducer = (history) => combineReducers({
  // react & redux 3rd party libs
  router: connectRouter(history),

  // cross package stuff
  modal: modalReducer,
  notifications: notificationsReducer,

  // packages specific store
  apps,
});

export default createReducer;
