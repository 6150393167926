import React from 'react';
import { Link } from '@saucelabs/ds-link';
import { IconSauceLogo } from '@saucelabs/ds-icons';

export const Header: React.FC = () => (
  <header className="pl-7.5 pt-1.75 pb-1.5">
    <Link
      isExternal
      rel="noopener noreferrer"
      to="https://saucelabs.com"
    >
      <IconSauceLogo />
    </Link>
  </header>
);

export default Header;
