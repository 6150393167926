import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { sentryCrashReporter, historyRedirectMiddleware } from '@saucelabs/sl-util-redux';
import thunk from 'redux-thunk';

import createRootReducer from './createReducer';

export const browserHistory = createBrowserHistory();
const middlewares = [
  applyMiddleware(
    sentryCrashReporter,
    thunk,
    historyRedirectMiddleware,
  ),
  applyMiddleware(routerMiddleware(browserHistory)),
];

if (window.__REDUX_DEVTOOLS_EXTENSION__) { // eslint-disable-line
  middlewares.push(window.__REDUX_DEVTOOLS_EXTENSION__()); // eslint-disable-line
}

const store = createStore(
  createRootReducer(browserHistory),
  {},
  compose(...middlewares),
);

if (module.hot) {
  module.hot.accept('./createReducer', () => {
    // eslint-disable-next-line global-require
    const nextRootReducer = require('./createReducer');

    store.replaceReducer(nextRootReducer);
  });
}

export default store;
